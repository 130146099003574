import localforage from 'localforage'
import { applySpec, head, join, juxt, pathOr, pipe } from 'ramda'

import Api from '../configuration'

const IP_INFO_KEY = 'USER_IP_INFO'

const ipInfo = async () => {
  const info = await localforage.getItem(IP_INFO_KEY).catch(() => false)

  if (info) {
    return info
  }

  const newInfo = await Api.base
    .get(
      'https://api.ipapi.com/api/check?access_key=6fcd12aa55eb7f74c16f51ceec9d7134',
    )
    .then(
      applySpec({
        callingCode: pathOr('91', ['result', 'location', 'calling_code']),
        country: pathOr('IN', ['result', 'country_code']),
        currency: pathOr('INR', ['result', 'currency', 'code']),
        language: pipe(
          pathOr(navigator?.language || navigator?.languages[0] || 'en-IN', [
            'result',
            'location',
            'languages',
          ]),
          head,
          pathOr('en-IN', ['code']),
        ),
        location: pipe(
          juxt([
            pathOr('', ['result', 'city']),
            pathOr('', ['result', 'region_name']),
            pathOr('', ['result', 'country_name']),
          ]),
          join(', '),
        ),
        timeZone: pathOr(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone, [
          'result',
          'timezone',
          'id',
        ]),
      }),
    )

  await localforage.setItem(IP_INFO_KEY, newInfo)

  return newInfo
}

export default ipInfo
